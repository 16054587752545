import React, { useEffect } from "react";
import { useState } from "react";
import {
  Center,
  Flex,
  Heading,
  Input,
  Select,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import FeedbackTable from "../../componenets/FeedbackTable/FeedbackTable";
import { useFeedbackMessageType } from "../../store/feedbackMessageType/reducer";
import { useDispatch } from "react-redux";
import { REQUEST_FEEDBACK_MESSAGE_TYPE } from "../../store/feedbackMessageType/feedbackMessageTypeActionTypes";
import { IFeedBackType } from "../../types/IFeedBackType";

const Feedback = () => {
  const [searchQuery, setSearch] = useState("");
  const [responseType, setResponseType] = useState("");
  const [responseMessageType, setResponseMessageType] = useState<IFeedBackType>(
    { _id: "", title: "" }
  );
  const {
    feedbackMessageType,
    busy,
  }: { feedbackMessageType: IFeedBackType[]; busy: boolean } =
    useFeedbackMessageType();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!feedbackMessageType.length && !busy) {
      dispatch({ type: REQUEST_FEEDBACK_MESSAGE_TYPE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <VStack top={5} position="relative" key={"vStack"}>
        <Flex w="92%">
          <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
            Feedbacks
          </Heading>
          <Spacer></Spacer>
          <Heading size="md" fontWeight="semibold">
            <Center>
              <Select
                placeholder="Select Feedback Type"
                key={"feedback-message-type"}
                marginEnd={2}
                onChange={(event) => {
                  if (!event.target.value.trim()) {
                    setResponseMessageType({ _id: "", title: "" });
                    return;
                  }
                  setResponseMessageType(
                    JSON.parse(event.target.value) as IFeedBackType
                  );
                }}
              >
                {feedbackMessageType.map((fmt) => {
                  return (
                    <option
                      value={JSON.stringify(fmt)}
                      key={fmt._id + "-" + fmt.title}
                    >
                      {fmt.title}
                    </option>
                  );
                })}
              </Select>
              <Select
                placeholder="Response type"
                marginEnd={2}
                key={"Response type"}
                onChange={(event) => {
                  setResponseType(event.target.value);
                }}
              >
                <option value="TRUE" key={"true"}>
                  TRUE
                </option>
                <option value="FALSE" key={"false"}>
                  FALSE
                </option>
              </Select>
              <Input
                id="search"
                key={"search"}
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></Input>
            </Center>
          </Heading>
        </Flex>
      </VStack>
      <FeedbackTable
        searchQuery={searchQuery}
        responseType={responseType}
        responseMessageType={responseMessageType}
      />
    </>
  );
};

export default Feedback;
