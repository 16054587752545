import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Drawer,
  DrawerBody,
  IconButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { Form, Formik } from "formik";
import { get } from "lodash";
import { useLinksMaster } from "../../store/linkMaster/reducer";
import * as Yup from "yup";
import { FormField } from "../form/formField/FormField";
import { useEffect, useMemo, useState } from "react";
import { useLinkCategories } from "../../store/linkCategory/reducer";
import { useDispatch } from "react-redux";
import {
  REQUEST_CREATE_MASTER_LINK,
  REQUEST_UPDATE_MASTER_LINK,
} from "../../store/linkMaster/linkActionTypes";
import { MdModeEditOutline } from "react-icons/md";
import ImageFormField from "../ImageFormField/ImageFormField";
import { ILinks } from "../../types/links";
interface Props {
  link?: ILinks;
  isOpen: any;
  onClose: any;
}

// const FILE_SIZE = 3 * 1024 * 1024;

// const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const addSchema = Yup.object({
  title: Yup.string().min(2, "Too Short!").required("Required"),
  logo: Yup.string().required("A file is required"),
  // .test(
  //   "fileSize",
  //   "File too large",
  //   (value) => value && value.size <= FILE_SIZE
  // )
  // .test(
  //   "fileFormat",
  //   "Unsupported Format",
  //   (value) => value && SUPPORTED_FORMATS.includes(value.type)
  // ),
  isPro: Yup.boolean().required("Required"),
  prefix: Yup.string().min(2, "Too Short!").optional(),
  suffix: Yup.string().min(2, "Too Short!").optional(),
  androidPrefix: Yup.string().min(2, "Too Short!").optional(),
  iosPrefix: Yup.string().min(2, "Too Short!").optional(),
  extraLabel: Yup.boolean().required("Required"),
  extraImage: Yup.boolean().required("Required"),
  extraPlaceholder: Yup.string().min(2, "Too Short!").optional(),
  placeholder: Yup.string().min(2, "Too Short!").required("Required"),
  type: Yup.object().required("Required"),

  // key: Yup.string().optional(),
  category: Yup.array()
    .required("Required")
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
      })
    )
    .min(1, "Select at least one Category"),
  forFreeUser: Yup.number().required("Required"),
  forPaidUser: Yup.number().required("Required"),
});

const LinkMasterEditDrawer: React.FC<Props> = ({ link, isOpen, onClose }) => {
  const { masterLinks, busy, message } = useLinksMaster();
  const dispatch = useDispatch();
  const { linkCategory } = useLinkCategories();

  const categoryOption = useMemo(
    () =>
      linkCategory.map((lc: Record<string, any>) => {
        return {
          label: lc.title,
          value: lc._id,
        };
      }),
    [linkCategory]
  );

  const typeOptions: any[] = useMemo(() => {
    return [
      { value: "text", label: "Text" },
      { value: "number", label: "Number" },
      { value: "links", label: "Contact Card" },
      { value: "Youtube Embedded", label: "Youtube Embedded" },
      { value: "email", label: "Email" },
      { value: "file", label: "File" },
    ];
  }, []);

  const initialValue = useMemo(() => {
    if (link) {
      return {
        title: link.title,
        logo: link.logo,
        isPro: link.isPro,
        prefix: link.prefix,
        suffix: link.suffix,
        androidPrefix: link.androidPrefix,
        iosPrefix: link.iosPrefix,
        placeholder: link.placeholder,
        extraLabel: link.extraLabel,
        extraImage: link.extraImage,
        extraPlaceholder: link.extraPlaceholder,
        type: typeOptions.find((to) => to.value === link.type),
        // key: link.key,
        forFreeUser: link.maxLinks.forFreeUser,
        forPaidUser: link.maxLinks.forPaidUser,
        category: (link.category || []).map(
          (value: string | Record<string, any>) => ({
            value,
            label: categoryOption.find((cat: any) => value === cat.value).label,
          })
        ),
      };
    }
    return {
      title: "",
      logo: "",
      isPro: false,
      prefix: "",
      suffix: "",
      androidPrefix: "",
      iosPrefix: "",
      placeholder: "",
      extraLabel: false,
      extraImage: false,
      extraPlaceholder: "",
      type: { value: "" },
      // key: "",
      forFreeUser: "",
      forPaidUser: "",
      category: [],
    };
  }, [categoryOption, link, typeOptions]);

  const onSubmit = (values: Record<string, any>) => {
    let payload: Record<string, any> = {
      title: values.title,
      isPro: values.isPro,
      prefix: values.prefix,
      suffix: values.suffix,
      androidPrefix: values.androidPrefix,
      iosPrefix: values.iosPrefix,
      placeholder: values.placeholder,
      extraLabel: values.extraLabel,
      extraImage: values.extraImage,
      extraPlaceholder: values.extraPlaceholder,
      type: values.type.value,
      // key: values.key,
      logo: values.logo,
      maxLinks: {
        forFreeUser: values.forFreeUser,
        forPaidUser: values.forPaidUser,
      },
      category: values.category.map((item: any) => {
        return item.value;
      }),
    };
    if (link) {
      const data = { payload, _id: link._id };
      dispatch({ type: REQUEST_UPDATE_MASTER_LINK, data });
    } else {
      dispatch({ type: REQUEST_CREATE_MASTER_LINK, payload });
    }
  };

  const toast = useToast();
  const links = useMemo(() => {
    return masterLinks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (link) {
      const linkFromStore = masterLinks.find((ml: any) => ml._id === link._id);
      if (linkFromStore !== link) {
        toast({
          title: "Success.",
          description: "Link is updated.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
      }
    } else if (links.length !== masterLinks.length) {
      toast({
        title: "Success.",
        description: "Link is created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [links, masterLinks, onClose, link, toast]);

  const [isDisabled, setDisabled] = useState(!!(link && link._id));

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors, touched, submitForm }) => (
        <Form>
          <Drawer
            isOpen={isOpen}
            placement="right"
            size={"md"}
            onClose={onClose}
            closeOnOverlayClick={busy}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
                <Flex>
                  {get(link, "title") || "Add link"}
                  <Spacer></Spacer>
                  {link && link._id && (
                    <IconButton
                      aria-label="update link"
                      key={"updateLinkButton"}
                      alignSelf={"end"}
                      onClick={() => {
                        setDisabled(!isDisabled);
                      }}
                      icon={<MdModeEditOutline />}
                    ></IconButton>
                  )}
                </Flex>

                {message && (
                  <Alert status="error">
                    <AlertTitle>{message}</AlertTitle>
                  </Alert>
                )}
              </DrawerHeader>
              <DrawerBody>
                <ImageFormField
                  disabled={isDisabled || busy}
                  image={values["logo"]}
                  onSuccess={({ img: image }: { img: string }) => {
                    if (image) setFieldValue("logo", image);
                  }}
                />
                <FormField
                  required={true}
                  disabled={isDisabled}
                  name="Name : "
                  fieldName="title"
                  type="text"
                  error={touched.title ? (errors.title as string) : undefined}
                />
                <Box paddingTop={1} paddingBottom={1}>
                  <FormControl isRequired>
                    <RadioGroup
                      onChange={(val) => {
                        setFieldValue("isPro", val === "true");
                      }}
                      value={values.isPro?.toString()}
                    >
                      <Stack direction="row">
                        <FormLabel>Pro :</FormLabel>
                        <Radio value={"true"}>PRO</Radio>
                        <Radio value={"false"}>FREE</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>
                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Prefix : "
                  fieldName="prefix"
                  type="text"
                  error={touched.prefix ? (errors.prefix as string) : undefined}
                />
                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Suffix : "
                  fieldName="suffix"
                  type="text"
                  error={touched.suffix ? (errors.suffix as string) : undefined}
                />
                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Android Prefix : "
                  fieldName="androidPrefix"
                  type="text"
                  error={
                    touched.androidPrefix
                      ? (errors.androidPrefix as string)
                      : undefined
                  }
                />
                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="IOS Prefix : "
                  fieldName="iosPrefix"
                  type="text"
                  error={
                    touched.iosPrefix ? (errors.iosPrefix as string) : undefined
                  }
                />
                <FormField
                  disabled={isDisabled}
                  required={true}
                  name="Place Holder : "
                  fieldName="placeholder"
                  type="text"
                  error={
                    touched.placeholder
                      ? (errors.placeholder as string)
                      : undefined
                  }
                />

                <Box paddingTop={1} paddingBottom={1}>
                  <FormControl isRequired>
                    <RadioGroup
                      onChange={(val) => {
                        setFieldValue("extraLabel", val === "true");
                      }}
                      value={values.extraLabel?.toString()}
                    >
                      <Stack direction="row">
                        <FormLabel>Extra label :</FormLabel>
                        <Radio value={"true"}>Allow</Radio>
                        <Radio value={"false"}>Deny</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box paddingTop={1} paddingBottom={1}>
                  <FormControl isRequired>
                    <RadioGroup
                      onChange={(val) => {
                        setFieldValue("extraImage", val === "true");
                      }}
                      value={values.extraImage?.toString()}
                    >
                      <Stack direction="row">
                        <FormLabel>Extra Image :</FormLabel>
                        <Radio value={"true"}>Allow</Radio>
                        <Radio value={"false"}>Deny</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>

                <FormField
                  disabled={isDisabled}
                  required={true}
                  name="Extra Place Holder : "
                  fieldName="extraPlaceholder"
                  type="text"
                  error={
                    touched.extraPlaceholder
                      ? (errors.extraPlaceholder as string)
                      : undefined
                  }
                />
                <FormControl isRequired>
                  <Flex width="100%">
                    <HStack>
                      <FormLabel>Type : </FormLabel>
                      <Spacer></Spacer>
                      <p>{errors.type}</p>
                    </HStack>
                  </Flex>
                  <Select
                    value={values["type"]}
                    name="type"
                    isDisabled={isDisabled}
                    onChange={(event) => {
                      if (event) {
                      }
                      //@ts-ignore
                      setFieldValue("type", event);
                    }}
                    options={typeOptions}
                    placeholder="Select a Type"
                  />
                </FormControl>
                {/* <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Key : "
                  fieldName="key"
                  type="text"
                  error={touched.key ? (errors.key as string) : undefined}
                /> */}
                <FormControl isRequired>
                  <Flex width="100%">
                    <HStack>
                      <FormLabel>Category : </FormLabel>
                      <Spacer></Spacer>
                      <Text color={"red"}>{errors.category}</Text>
                    </HStack>
                  </Flex>
                  <Select
                    isMulti={true}
                    isDisabled={isDisabled}
                    value={values["category"]}
                    name="category"
                    onChange={(event) => {
                      setFieldValue("category", event);
                    }}
                    options={categoryOption}
                    placeholder="Select a Category"
                  />
                </FormControl>
                <FormField
                  disabled={isDisabled}
                  required={true}
                  name="Free User : "
                  fieldName="forFreeUser"
                  type="number"
                  error={
                    touched.forFreeUser
                      ? (errors.forFreeUser as string)
                      : undefined
                  }
                />
                <FormField
                  disabled={isDisabled}
                  required={true}
                  name="Paid User : "
                  fieldName="forPaidUser"
                  type="number"
                  error={
                    touched.forPaidUser
                      ? (errors.forPaidUser as string)
                      : undefined
                  }
                />
              </DrawerBody>
              <DrawerFooter backgroundColor={"gray.100"}>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={onClose}
                  disabled={busy}
                >
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={submitForm}
                  disabled={busy || isDisabled}
                >
                  {link && link._id ? "Update" : "Save"}
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Form>
      )}
    </Formik>
  );
};
export default LinkMasterEditDrawer;
