import axios from "axios";
import { Fragment } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import AuthenticatedRoute from "./componenets/Auth/AuthenticatedRoute";
import Sidebar from "./componenets/Header/Sidebar";
import Login from "./pages/Login/Login";
import Accounts from "./pages/Accounts/Accounts";
// import User from "./pages/User/User";
import BetaUser from "./pages/BetaUser/BetaUser";
import LinksMaster from "./pages/LinksMaster/LinksMaster";
import { get } from "lodash";
import Feedback from "./pages/Feedback/Feedback";
import LinksCategoryMaster from "./pages/LinksCategoryMaster/LinksCategoryMasterTable";

const App = () => {
  axios.interceptors.request.use(
    (config: any) => {
      config.headers.authorization = localStorage.getItem("token");
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (config) => {
      return config;
    },
    (error) => {
      if (get(error, "response.status") === 403) {
        localStorage.clear();
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  return (
    <Router>
      <Fragment>
        <Routes>
          <Route
            path="/login"
            element={
              <>
                <Login />
              </>
            }
          ></Route>
          <Route
            path="/"
            element={
              <>
                <Sidebar />
                <AuthenticatedRoute />
              </>
            }
          >
            <Route path="/" element={<Accounts />}></Route>
            {/* <Route path="/links" element={<Links />}></Route> */}
            <Route path="/links" element={<LinksMaster />}></Route>
            <Route path="/beta_user" element={<BetaUser />}></Route>
            <Route path="/feedback" element={<Feedback />}></Route>
            <Route
              path="/links_category"
              element={<LinksCategoryMaster />}
            ></Route>
            {/* <Route path="/user" element={<User />}></Route> */}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Routes>
      </Fragment>
    </Router>
  );
};

export default App;
