import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { Center, Flex, Heading, Input, Spacer, VStack } from "@chakra-ui/react";
import AccountTable from "../../componenets/AccountTable/AccountsTable";
import { useAccounts } from "../../store/account/reducer";
import { REQUEST_ACCOUNTS } from "../../store/account/accountActionType";

const Accounts = () => {
  const { accounts, busy } = useAccounts();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!busy && !(accounts || []).length) {
      dispatch({ type: REQUEST_ACCOUNTS });
    }
  }, []);

  const [searchQuery, setSearch] = useState("");
  return (
    <>
      <VStack top={5} position="relative" key={"vStack"}>
        <Flex w="92%">
          <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
            Accounts
          </Heading>
          <Spacer></Spacer>
          <Heading size="md" fontWeight="semibold">
            <Center>
              <Input
                id="search"
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></Input>
            </Center>
          </Heading>
        </Flex>
      </VStack>
      <AccountTable query={searchQuery} />
    </>
  );
};

export default Accounts;
