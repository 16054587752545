import React from "react";
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  Button,
  Flex,
  HStack,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { FiMenu, FiCalendar, FiUser } from "react-icons/fi";

import { MdCategory, MdFeedback } from "react-icons/md";

import { Link } from "react-router-dom";
import NavItem from "./NavItem";
import { BiLogOut } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { REQUEST_LOGOUT_USER } from "../../store/user/userActionType";
export default function Sidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const dispatch = useDispatch();

  return (
    <>
      <IconButton
        icon={<FiMenu />}
        //@ts-ignore
        ref={btnRef}
        onClick={onOpen}
        top={5}
        left={5}
        bg="transparent"
        position="absolute"
        zIndex={100}
      ></IconButton>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        //@ts-ignore
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton _focus={{ border: "none" }} />
          <DrawerHeader borderBottomWidth="1px">Slynk</DrawerHeader>

          <DrawerBody onClick={onClose}>
            <Flex height="100%">
              <VStack alignItems="baseline">
                <Link to="/">
                  <NavItem icon={FiCalendar} title="Dashboard" />
                </Link>

                <Link to="/links">
                  <NavItem icon={MdCategory} title="Links" />
                </Link>

                <Link to="/beta_user">
                  <NavItem icon={FiUser} title="Beta User" />
                </Link>

                <Link to="/user">
                  <NavItem icon={FiUser} title="Users" />
                </Link>

                <Link to="/feedback">
                  <NavItem icon={MdFeedback} title="Feedbacks" />
                </Link>
                <Spacer></Spacer>

                <IconButton
                  icon={<NavItem icon={BiLogOut} title="Logout" />}
                  aria-label="Logout"
                  title="LogOut"
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                    dispatch({ type: REQUEST_LOGOUT_USER });
                  }}
                />
              </VStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
