import { useSelector } from "react-redux";
import {
  LOGOUT_USER,
  REQUEST_LOGOUT_USER,
  REQUEST_USERS,
  SET_USERS,
} from "./userActionType";

const initialState = {
  busy: false,
  message: "",
  users: [],
};
const users = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_USERS:
    case REQUEST_LOGOUT_USER:
      return {
        ...state,
        busy: true,
        message: "",
      };
    case SET_USERS:
      return {
        ...state,
        busy: false,
        message: "",
        users: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        busy: false,
        message: "",
        users: [],
      };
    default:
      return state;
  }
};
export default users;

export function useUsers() {
  return useSelector((state: Record<string, any>) => state.users);
}
