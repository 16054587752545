import {
  Button,
  IconButton,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { isArray } from "lodash";
import { useState } from "react";
import { BiHide, BiShowAlt } from "react-icons/bi";
import { IoMdTrash } from "react-icons/io";
import { useDispatch } from "react-redux";
import { REQUEST_LINK_CATEGORY } from "../../store/linkCategory/linkCategoryActionType";
import { useLinkCategories } from "../../store/linkCategory/reducer";
import { REQUEST_MASTER_LINKS } from "../../store/linkMaster/linkActionTypes";
import { useLinksMaster } from "../../store/linkMaster/reducer";
import { ILinks } from "../../types/links";
import LinkMasterEditDrawer from "../drawer/LinkMasterEditDrawer";
import LinkDeactiveConfirmation from "../modals/linkDeactiveConfirmation";
import LinkDeleteConfirmation from "../modals/LinkDeleteConfirmation";

const LinksMasterTable: React.FC = () => {
  const dispatch = useDispatch();
  const { masterLinks: links, busy } = useLinksMaster();
  const { linkCategory, busy: linkCategoryBusy } = useLinkCategories();
  if (!busy && !(links || []).length) {
    dispatch({ type: REQUEST_MASTER_LINKS });
  }
  if (!linkCategoryBusy && !(linkCategory || []).length) {
    dispatch({ type: REQUEST_LINK_CATEGORY });
  }

  const [targetLink, setTargetLink] = useState<ILinks>();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const deleteLink = (linkMaster: ILinks) => {
    setTargetLink(linkMaster);
    onDeleteOpen();
  };

  const {
    isOpen: isDeativeOpen,
    onOpen: onDeativeOpen,
    onClose: onDeativeClose,
  } = useDisclosure();

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const viewLink = (linkMaster: ILinks) => {
    setTargetLink(linkMaster);
    onViewOpen();
  };

  const hideLink = (linkMaster: ILinks) => {
    setTargetLink(linkMaster);
    onDeativeOpen();
  };

  const getCategoryName = (categoryId: any) => {
    let titles: string[] = [];
    (linkCategory || []).forEach((category: any) => {
      if (categoryId.includes(category._id)) {
        titles.push(category.title);
      }
    });
    return titles && titles.length ? titles.join(", ") : categoryId;
  };

  return (
    <>
      <div
        style={{
          overflow: "scroll",
          width: "95vw",
          maxWidth: "95vw",
          height: "85vh",
          maxHeight: "85vh",
          top: "10vh ",
          left: "5vw",
          position: "relative",
          paddingBottom: "100px",
        }}
      >
        <Table variant="simple">
          <TableCaption>Links master Details</TableCaption>
          <Thead
            style={{
              position: "sticky",
              top: "0vh ",
              backgroundColor: "#fff",
              zIndex: "100",
            }}
          >
            <Tr>
              <Th>Sr. No.</Th>
              <Th></Th>
              <Th>Title</Th>
              <Th>Logo</Th>
              <Th>Pro</Th>
              {/* <Th>Prefix</Th>
              <Th>Suffix</Th>
              <Th>Android Prefix</Th>
              <Th>IOS Prefix</Th>
              <Th>Placeholder</Th> */}
              <Th>Type</Th>
              {/* <Th>Key</Th> */}
              <Th>category</Th>
              {/* <Th>forFreeUser</Th>
              <Th>forPaidUser</Th> */}
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          {isArray(links) && links.length ? (
            <Tbody>
              {links.map((value: ILinks, index: number) => {
                return (
                  <Tr key={value._id}>
                    <Td key={"Serial number"}>{index + 1}</Td>
                    <Td key={"show"}>
                      <IconButton
                        aria-label="Show"
                        icon={value.isDeactive ? <BiHide /> : <BiShowAlt />}
                        onClick={() => {
                          hideLink(value);
                        }}
                      ></IconButton>
                    </Td>
                    <Td key={"title"}>{value.title}</Td>
                    <Td key={"image"}>
                      <img src={value.logo} alt="" width={50}></img>
                    </Td>
                    <Td key={"pro"}>{value.isPro ? "TRUE" : "FALSE"}</Td>
                    {/* <Td key={"prefix"}>{value.prefix}</Td>
                    <Td key={"suffix"}>{value.suffix}</Td>
                    <Td key={"androidPrefix"}>{value.androidPrefix}</Td>
                    <Td key={"iosPrefix"}>{value.iosPrefix}</Td>
                    <Td key={"placeholder"}>{value.placeholder}</Td> */}
                    <Td key={"type"}>{value.type}</Td>
                    {/* <Td key={"key"}>{value.key}</Td> */}
                    <Td key={"category"}>{getCategoryName(value.category)}</Td>
                    {/* <Td key={"forFreeUser"}>{value.maxLinks.forFreeUser}</Td>
                    <Td key={"forPaidUser"}>{value.maxLinks.forPaidUser}</Td> */}
                    <Td
                      key={"edit_link"}
                      onClick={() => {
                        viewLink(value);
                      }}
                    >
                      <Button
                        aria-label="Show"
                        // icon={value.isDeactive ? <BiHide /> : <BiShowAlt />}
                        // icon={<BiShowAlt />}
                      >
                        View
                      </Button>
                    </Td>
                    <Td key={"delete"}>
                      <IconButton
                        aria-label="delete link"
                        key={"deleteLinkButton"}
                        marginLeft={2}
                        onClick={() => {
                          deleteLink(value);
                        }}
                        icon={<IoMdTrash />}
                      ></IconButton>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          ) : (
            <Tbody>
              <Tr>
                <Td colSpan={15} textAlign={"center"} alignItems={"center"}>
                  Loadding...
                </Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </div>
      {isDeleteOpen && targetLink && (
        <LinkDeleteConfirmation
          link={targetLink}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
      {isDeativeOpen && targetLink && (
        <LinkDeactiveConfirmation
          link={targetLink}
          isOpen={isDeativeOpen}
          onClose={onDeativeClose}
        />
      )}

      {isViewOpen && (
        <LinkMasterEditDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          link={targetLink}
        />
      )}
    </>
  );
};

export default LinksMasterTable;
