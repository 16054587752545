import {
  Alert,
  AlertTitle,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ERROR_SEND_ADMIN_FEEDBACK,
  REQUEST_SEND_ADMIN_FEEDBACK,
} from "../../store/feedback/feedbackActionType";
import { useFeedback } from "../../store/feedback/reducer";
import { IFeedBack } from "../../types/feedback";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { FormField } from "../form/formField/FormField";
import "react-quill/dist/quill.snow.css";
import { FormFieldEditor } from "../form/formField/FormFieldEditor";

interface Props {
  isOpen: any;
  onClose: any;
  feedbacks?: IFeedBack;
}

const addSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address format")

    .required("Email is required"),
  subject: Yup.string().min(2, "Too Short!").required("Required"),
  message: Yup.string().min(2, "Too Short!").required("Required"),
});
const FeedbackReplyDrawer: React.FC<Props> = ({
  feedbacks,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();

  const { busy, message, replySend } = useFeedback();
  const [isDisabled, setDisabled] = useState(!!(feedbacks && feedbacks._id));
  const initialValue = useMemo(() => {
    if (feedbacks) {
      return {
        email: feedbacks.email,
        subject: "",
        message: "",
      };
    }
    return {
      email: "",
      subject: "",
      message: "",
    };
  }, [feedbacks]);

  const onSubmit = (values: Record<string, any>) => {
    dispatch({
      type: ERROR_SEND_ADMIN_FEEDBACK,
      payload: "none",
    });
    let payload: Record<string, any> = {
      email: values.email,
      subject: values.subject,
      message: values.message,
    };

    dispatch({ type: REQUEST_SEND_ADMIN_FEEDBACK, payload });
  };
  const toast = useToast();
  useEffect(() => {
    console.log(replySend);
    if (replySend) {
      toast({
        title: "Success.",
        description: "Reply has been Successfully Sent.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [replySend, toast, onClose]);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size={"md"}
        onClose={onClose}
        closeOnOverlayClick={busy}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
            <Flex>
              <Spacer></Spacer>
              {isDisabled ? (
                <Button
                  onClick={() => {
                    dispatch({
                      type: ERROR_SEND_ADMIN_FEEDBACK,
                      payload: "none",
                    });
                    setDisabled(!isDisabled);
                  }}
                >
                  Reply
                </Button>
              ) : (
                <Button
                  disabled={busy}
                  onClick={() => {
                    setDisabled(!isDisabled);
                  }}
                >
                  Show Mail
                </Button>
              )}
            </Flex>

            {message && (
              <Alert status="error">
                <AlertTitle>{message}</AlertTitle>
              </Alert>
            )}
          </DrawerHeader>
          <DrawerBody>
            {isDisabled ? (
              <>
                <Text as="samp" fontSize="20px">
                  Sender :
                </Text>
                <Text>{feedbacks?.email}</Text>
                <Text as="samp" fontSize="20px">
                  Message Type :
                </Text>
                <Text>{get(feedbacks, "messageType.title")}</Text>
                <Text as="samp" fontSize="20px">
                  Message :
                </Text>
                <Text>{feedbacks?.message}</Text>
              </>
            ) : (
              <>
                <Formik
                  initialValues={initialValue}
                  validationSchema={addSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, setFieldValue, errors, touched, submitForm }) => (
                    <Form>
                      <FormField
                        disabled={true}
                        required={true}
                        name="To : "
                        fieldName="email"
                        type="email"
                        error={
                          touched.email ? (errors.email as string) : undefined
                        }
                      />
                      <FormField
                        disabled={isDisabled}
                        required={true}
                        name="Subject : "
                        fieldName="subject"
                        type="text"
                        error={
                          touched.subject
                            ? (errors.subject as string)
                            : undefined
                        }
                      />
                      <FormFieldEditor
                        disabled={isDisabled}
                        required={true}
                        name="Message : "
                        fieldName="message"
                        type="textarea"
                        error={
                          touched.message
                            ? (errors.message as string)
                            : undefined
                        }
                      />
                      <Button
                        colorScheme="blue"
                        onClick={submitForm}
                        disabled={busy}
                      >
                        Send
                      </Button>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </DrawerBody>
          <DrawerFooter backgroundColor={"gray.100"}>
            <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default FeedbackReplyDrawer;
