import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { get, isArray } from "lodash";
import { IFeedBack } from "../../types/feedback";
import { useFeedback } from "../../store/feedback/reducer";
import { useDispatch } from "react-redux";
import {
  ERROR_SEND_ADMIN_FEEDBACK,
  REQUEST_FEEDBACK_LIST,
} from "../../store/feedback/feedbackActionType";
import { IFeedBackType } from "../../types/IFeedBackType";
import FeedbackReplyDrawer from "../drawer/FeedbackReplyDrawer";

interface Props {
  searchQuery: string;
  responseType: string;
  responseMessageType: IFeedBackType;
}

const FeedbackTable: React.FC<Props> = ({
  searchQuery: query,
  responseType,
  responseMessageType,
}) => {
  const dispatch = useDispatch();

  const {
    feedback,
    busy,
    message,
  }: {
    busy: boolean;
    message: string;
    feedback: IFeedBack[];
    replySend: boolean;
  } = useFeedback();

  const [targetFeedback, setTargetFeedback] = useState<IFeedBack>();
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const viewFeedback = (feedback: IFeedBack) => {
    setTargetFeedback(feedback);
    onViewOpen();
  };

  useEffect(() => {
    if (!feedback.length && !busy && !message) {
      dispatch({ type: REQUEST_FEEDBACK_LIST });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const searchColumns = useMemo(
    () => ["email", "message", "messageType", "messageType", "subject"],
    []
  );

  const search = (feedback: IFeedBack) => {
    return (
      searchColumns.some((column) => {
        return (
          get(feedback, column, "")
            .toString()
            .toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        );
      }) &&
      (!responseType.length ||
        (responseType === "TRUE" && feedback.responseType) ||
        (responseType === "FALSE" && !feedback.responseType)) &&
      (!responseMessageType.title.trim() ||
        responseMessageType.title === get(feedback, "messageType.title"))
    );
  };
  return (
    <>
      <div
        style={{
          overflow: "scroll",
          width: "90vw",
          maxWidth: "90vw",
          height: "85vh",
          maxHeight: "85vh",
          top: "10vh ",
          left: "5vw",
          position: "relative",
          paddingRight: "50px",
          paddingBottom: "100px",
        }}
      >
        <Table variant="simple">
          <Thead
            key={"Table-header-body"}
            style={{
              position: "sticky",
              top: "0vh ",
              backgroundColor: "#fff",
              zIndex: "100",
            }}
          >
            <Tr>
              <Th key={"sr No"}>Sr. No.</Th>
              <Th key={"email"}>Email</Th>
              <Th key={"Message Type"}>Message Type</Th>
              <Th key={"Subject"}>Subject</Th>
              <Th key={"Message"}>Message</Th>
              <Th key={"responseType"}>responseType</Th>
              <Th></Th>
            </Tr>
          </Thead>
          {isArray(feedback) && feedback.length ? (
            <Tbody key={"Table-body"}>
              {feedback
                .filter(search)
                .map((value: IFeedBack, index: number) => {
                  return (
                    <Tr key={value._id}>
                      <Td key={"Sr. No"}>{index + 1}</Td>
                      <Td key={"Email"}>{value.email}</Td>
                      <Td key={"Message Type"}>
                        {get(value, "messageType.title")}
                      </Td>
                      <Td key={"Subject"}>{value.subject}</Td>
                      <Td key={"Message"}>{value.message}</Td>
                      <Td key={"responseType"}>
                        {value.responseType ? "true" : "false"}
                      </Td>
                      <Td
                        key={"edit_link"}
                        onClick={() => {
                          dispatch({
                            type: ERROR_SEND_ADMIN_FEEDBACK,
                            payload: "none",
                          });

                          viewFeedback(value);
                        }}
                      >
                        <Button
                          aria-label="Show"
                          // icon={value.isDeactive ? <BiHide /> : <BiShowAlt />}
                          // icon={<BiShowAlt />}
                        >
                          View
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          ) : (
            <Tbody>
              <Tr>
                <Td>Nothing to show here</Td>
              </Tr>
            </Tbody>
          )}
          <Tbody></Tbody>
        </Table>
      </div>

      {isViewOpen && (
        <FeedbackReplyDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          feedbacks={targetFeedback}
        />
      )}
    </>
  );
};

export default FeedbackTable;
