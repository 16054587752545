import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DELETE_USER_REQUEST } from "../../store/account/accountActionType";
import { useAccounts } from "../../store/account/reducer";
import { IAccount } from "../../types/account";
interface Props {
  account: IAccount;
  isOpen: any;
  onClose: any;
}
const AccountDeleteConfirmation: React.FC<Props> = ({
  isOpen,
  onClose,
  account,
}) => {
  const dispatch = useDispatch();
  const { busy, message, accounts } = useAccounts();

  useEffect(() => {
    if (
      !busy &&
      !message &&
      !(accounts || []).find(
        (acc: Record<string, any>) => acc._id === account._id
      )
    ) {
      onClose();
    }
  }, [account._id, accounts, busy, message, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Account</ModalHeader>
        <ModalCloseButton disabled={busy} />
        <ModalBody>
          <Text color="red">Are you sure to remove this User ?</Text>
          <Text> {get(account, "user.email")}</Text>
          {message && (
            <Alert status="error" zIndex={100}>
              <AlertIcon />
              <AlertTitle mr={2}>{message}</AlertTitle>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            onClick={() => {
              if (get(account, "user._id")) {
                dispatch({
                  type: DELETE_USER_REQUEST,
                  payload: { userId: get(account, "user._id") },
                });
              }
            }}
            disabled={busy}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default AccountDeleteConfirmation;
