import { get } from "lodash";
import { useSelector } from "react-redux";
import { IAccount } from "../../types/account";
import {
  DELETE_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  REQUEST_ACCOUNTS,
  SET_ACCOUNTS,
} from "./accountActionType";

const initialState: {
  busy: boolean;
  message: string;
  accounts: IAccount[];
} = {
  busy: false,
  message: "",
  accounts: [],
};

const accounts = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_ACCOUNTS:
      return {
        ...state,
        message: "",
        busy: true,
      };

    case SET_ACCOUNTS:
      return {
        ...state,
        busy: false,
        message: "",
        accounts: action.payload,
      };

    case DELETE_USER_REQUEST:
      return {
        ...state,
        message: "",
        busy: true,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        busy: false,
        message: "",
        accounts: state.accounts.filter(
          (account) => get(account, "user._id") !== action.payload
        ),
      };

    case DELETE_USER_ERROR:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };

    default:
      return state;
  }
};

export default accounts;

export function useAccounts() {
  return useSelector((state: Record<string, any>) => state.accounts);
}
