import React, { useMemo } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { get, isArray } from "lodash";
import { useBetaUser } from "../../store/betaUser/reducer";
import { IBetaUser } from "../../types/betaUser";

interface BetaUserProps {
  query: string;
}

const BetaUserTable: React.FC<BetaUserProps> = ({ query }) => {
  const { betaUser }: { betaUser: IBetaUser; busy: boolean; message: string } =
    useBetaUser();
  const searchColumns = useMemo(() => {
    return [
      "firstName",
      "lastName",
      "user.email",
      "email",
      "phoneNumber",
      "platform",
    ];
  }, []);

  const search = (betaUser: Record<string, any>) => {
    return searchColumns.some((column) => {
      return (
        get(betaUser, column, "")
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    });
  };

  return (
    <>
      <div
        style={{
          overflow: "scroll",
          width: "90vw",
          maxWidth: "90vw",

          top: "10vh ",
          left: "5vw",
          position: "relative",
          paddingRight: "50px",
          paddingBottom: "100px",
        }}
      >
        <Table variant="simple">
          <Thead
            key={"Table-header-body"}
            style={{
              position: "sticky",
              top: "0vh ",
              backgroundColor: "#fff",
              zIndex: "100",
            }}
          >
            <Tr>
              <Th key={"sr No"}>Sr. No.</Th>
              <Th key={"firstName"}>FirstName</Th>
              <Th key={"lastName"}>Last Name</Th>
              <Th key={"email"}>Invite Email</Th>
              <Th key={"registerMail"}>Register Mail</Th>
              <Th key={"platform"}>Platform</Th>
              <Th key={"phoneNumber"}>Phone Number</Th>
              {/* <Th key={"delete"}></Th> */}
              {/* <Th key={"request delete"}>Request for Delete </Th> */}
            </Tr>
          </Thead>
          {isArray(betaUser) && betaUser.length ? (
            <Tbody key={"Table-body"}>
              {betaUser
                .filter(search)
                .map((value: IBetaUser, index: number) => {
                  return (
                    <Tr key={value._id}>
                      <Td key={"Sr. No"}>{index + 1}</Td>
                      <Td key={"firstName"}>{value.firstName}</Td>
                      <Td key={"lastName"}>{value.lastName}</Td>
                      <Td key={"email"}>{value.email}</Td>
                      <Td key={"registerMail"}>{get(value, "user.email")}</Td>
                      <Td key={"platform"}>{value.platform}</Td>
                      <Td key={"phoneNumber"}>{value.phoneNumber}</Td>
                      {/* <Td key={"delete"}>
                      <IconButton
                        aria-label="Delete betaUser"
                        icon={<IoMdTrash />}
                        marginLeft={2}
                        disabled={value.isDeleted}
                        onClick={() => {
                          if (value.userType === "ADMIN") {
                            if (
                              window.confirm(
                                "Are you sure to DELETE this user. This user is ADMIN"
                              )
                            ) {
                              setaddErrorMsg("");
                              const update = {
                                value,
                                index,
                              };
                              deleteFunction(update);
                            }
                          } else {
                            setaddErrorMsg("");

                            DeleteData(value, index);
                          }
                        }}
                      />
                    </Td> */}

                      {/* <Td key={"delete request"}>
                      {value.deleteRequest ? (
                        <IconButton
                          aria-label="Delete User Request"
                          icon={<FiCheck />}
                          marginLeft={2}
                          disabled={value.isDeleted}
                          onClick={() => {
                            setaddErrorMsg("");

                            DeleteData(value, index);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Td> */}
                    </Tr>
                  );
                })}
            </Tbody>
          ) : (
            <Tbody>
              <Tr>
                <Td>Nothing to show here</Td>
              </Tr>
            </Tbody>
          )}
          <Tbody></Tbody>
        </Table>
      </div>
    </>
  );
};

export default BetaUserTable;
