import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_LOGOUT_USER,
  REQUEST_USERS,
  SET_USERS,
} from "./userActionType";
function* requestUsers(action: Record<string, any>): any {
  try {
    const result: any = yield call(getLinkMaster);
    yield put({ type: SET_USERS, payload: result.data });
  } catch (err) {
    console.error(`Error fetching switched account`, action);
  }
}
function* requestLogOutUser(): any {
  try {
    yield call(logOutUser);
  } catch (err) {
    console.error(`Error fetching switched account`);
  }
}

export function getLinkMaster() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/user/all`,
    withCredentials: true,
  });
}

export function logOutUser() {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/user/logout`,
    withCredentials: true,
  });
}
const userSaga = function* () {
  yield all([
    takeLatest(REQUEST_USERS, requestUsers),
    takeLatest(REQUEST_LOGOUT_USER, requestLogOutUser),
  ]);
};
export default userSaga;
