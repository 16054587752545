import {
  Center,
  Flex,
  Heading,
  HStack,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import LinkMasterEditDrawer from "../../componenets/drawer/LinkMasterEditDrawer";
import LinksMasterTable from "../../componenets/LinksMasterTable/LinksMasterTable";

const LinksMaster = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <VStack top={5} position="relative">
        <Flex w="92%">
          <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
            Links
          </Heading>

          <Spacer></Spacer>
          <Heading size="md" fontWeight="semibold">
            <HStack>
              <Center
                bg="tomato"
                h="100%"
                color="white"
                p={3}
                onClick={() => {
                  onOpen();
                  //   setaddErrorMsg("");
                }}
                style={{ cursor: "pointer" }}
              >
                + Add Links
              </Center>
            </HStack>
          </Heading>
        </Flex>
      </VStack>
      <LinksMasterTable />
      {isOpen && <LinkMasterEditDrawer isOpen onClose={onClose} />}
    </>
  );
};

export default LinksMaster;
