import { combineReducers } from "@reduxjs/toolkit";
import linksMaster from "./linkMaster/reducer";
import auth from "./auth/reducer";
import accounts from "./account/reducer";
import betaUsers from "./betaUser/reducer";
import linkCategories from "./linkCategory/reducer";
import users from "./user/reducer";
import feedbacks from "./feedback/reducer";
import feedbackMessageType from "./feedbackMessageType/reducer";
export default combineReducers({
  auth,
  linksMaster,
  accounts,
  betaUsers,
  linkCategories,
  users,
  feedbacks,
  feedbackMessageType,
});
