import React, { useEffect, useState } from "react";
import { ILinksCategory } from "../../types/linksCategory";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { ListManager } from "react-beautiful-dnd-grid";
import { ILinks } from "../../types/links";
import styles from "./LinkCategoryModal.module.css";

interface Props {
  catData?: ILinksCategory;
  isOpen: any;
  onClose: any;
}

interface ListElementPropsType {
  item: ILinks;
}

const ListElement: React.FC<ListElementPropsType> = ({ item }) => {
  return (
    <>
      <div className={styles.socialIconDivDrag}>
        <div></div>
        <img src={item.logo} className={styles.socialIcon} alt={item.logo} />
        <p>{item.title}</p>
      </div>
    </>
  );
};

const LinkCategoryModal: React.FC<Props> = ({ catData, isOpen, onClose }) => {
  const [sortedList, setSortedList] = useState<any>();
  const sortList = () => {
    const newArray = sortedList
      .sort((a: any, b: any) => a.order - b.order)
      .map((list: any, index: number) => {
        let obj = { ...list, index: index };
        return obj;
      });
    const noOrder = newArray.map((link: any, index: number) => {
      const temp = { ...link };
      delete temp.order;
      delete temp.index;
      return temp;
    });
    setSortedList([...newArray]);
  };
  const noop = function (sourceIndex: any, destinationIndex: any) {
    if (destinationIndex === sourceIndex) {
      return;
    }
    if (destinationIndex === 0) {
      sortedList[sourceIndex].order = sortedList[0].order - 1;
      sortList();
      return;
    }

    if (destinationIndex === sortedList.length - 1) {
      sortedList[sourceIndex].order =
        sortedList[sortedList.length - 1].order + 1;
      sortList();
      return;
    }
    if (destinationIndex < sourceIndex) {
      sortedList[sourceIndex].order =
        (sortedList[destinationIndex].order +
          sortedList[destinationIndex - 1].order) /
        2;

      // setSortedList

      sortList();
      return;
    }
    sortedList[sourceIndex].order =
      (sortedList[destinationIndex].order +
        sortedList[destinationIndex + 1].order) /
      2;

    sortList();
  };

  const sortedLink = (links: ILinks[]) => {
    // setLinks(links);
    // const link_id = links.map((link: any) => {
    //   return link._id;
    // });
    // axios({
    //   url: "/backend/account/" + accountData._id,
    //   method: "PATCH",
    //   data: { links: link_id },
    //   withCredentials: true,
    // })
    //   .then((data) => {
    //     const target = data.data;
    //     const source = { links: links };
    //     const updated = Object.assign(target, source);
    //     setAccountData(updated);
    //     dispatch(addAccount(updated));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  useEffect(() => {
    let temps = catData?.links.map((link: any, index: number) => {
      let temp = { ...link, order: index };
      return temp;
    });
    setSortedList(temps);
    console.log("updated", temps);
  }, []);
  console.log(catData);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modal Title</ModalHeader>
        <ModalCloseButton />
        <ModalBody className={styles.socialIconsMainDivDrag}>
          <ListManager
            items={sortedList as ILinks[]}
            direction="horizontal"
            maxItems={3}
            render={(links: ILinks) => <ListElement item={links} />}
            onDragEnd={noop}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost" onClick={() => sortedLink(sortedList)}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LinkCategoryModal;
