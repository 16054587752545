import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Center, Flex, Heading, Input, Spacer, VStack } from "@chakra-ui/react";
import BetaUserTable from "../../componenets/BetaUserTable/BetaUserTable";
import { useBetaUser } from "../../store/betaUser/reducer";
import { REQUEST_BETA_USERS } from "../../store/betaUser/betaUserActionType";

const BetaUser = () => {
  const { betaUser, busy } = useBetaUser();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!busy && !(betaUser || []).length) {
      dispatch({ type: REQUEST_BETA_USERS });
    }
  }, []);
  const [searchQuery, setSearch] = useState("");

  return (
    <>
      <VStack top={5} position="relative" key={"vStack"}>
        <Flex w="92%">
          <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
            Beta User
          </Heading>

          <Spacer></Spacer>

          <Heading size="md" fontWeight="semibold">
            <Center>
              <Input
                id="search"
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></Input>
            </Center>
          </Heading>
        </Flex>
      </VStack>
      <BetaUserTable query={searchQuery} />
    </>
  );
};

export default BetaUser;
