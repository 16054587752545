import {
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { IoMdLogIn, IoMdTrash } from "react-icons/io";
import { get, isArray } from "lodash";
import axios from "axios";
import { useAccounts } from "../../store/account/reducer";
import AccountDeleteConfirmation from "../modals/accountDeleteConfirmation";
import { IAccount } from "../../types/account";
import moment from "moment";
interface AccountTableProps {
  query: string;
}
const AccountTable: React.FC<AccountTableProps> = ({ query }) => {
  const { accounts }: { accounts: IAccount[] } = useAccounts();
  const [errorMsg, setErrorMsg] = useState("");
  const [deleteData, setDeleteData] = useState<IAccount>();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const searchColumns = useMemo(
    () => ["firstName", "lastName", "user.email", "user.phoneNumber"],
    []
  );
  const getAuthToken = (userId: string) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/auth/login_with_admin/${userId}`,
      withCredentials: true,
    })
      .then((res) => {
        if (get(res, "data.token")) {
          window.open(
            `https://beta.slynk.app/?token=${encodeURIComponent(
              res.data.token
            )}`,
            "_blank"
          );
        } else {
          setErrorMsg(
            "Something went wrong, please try again after some time or Refresh the Page."
          );
        }
      })
      .catch((error) => {
        setErrorMsg(
          get(error, "response.data.message") ??
            "Something went wrong, please try again after some time or Refresh the Page."
        );
      });
  };

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const openDeleteDataModal = (value: IAccount) => {
    setDeleteData(value);
    setIsDelete(true);
    onDeleteOpen();
  };
  const search = (account: Record<string, any>) => {
    return searchColumns.some((column) => {
      return (
        get(account, column, "")
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    });
  };
  return (
    <>
      {errorMsg ? (
        <Alert status="error" zIndex={100}>
          <AlertIcon />
          <AlertTitle mr={2}>{errorMsg}</AlertTitle>

          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMsg("")}
          />
        </Alert>
      ) : null}
      <div
        style={{
          overflow: "scroll",
          width: "95vw",
          maxWidth: "95vw",
          height: "85vh",
          maxHeight: "85vh",
          top: "10vh ",
          left: "5vw",
          position: "relative",
          paddingBottom: "100px",
        }}
      >
        <Table variant="simple">
          <Thead
            key={"Table-header-body"}
            style={{
              position: "sticky",
              top: "0vh ",
              backgroundColor: "#fff",
              zIndex: "100",
            }}
          >
            <Tr>
              <Th key={"sr NO"}>Sr. No.</Th>
              <Th key={"fName"}>First Name</Th>
              <Th key={"lName"}>Last Name</Th>
              <Th key={"email"}>Email</Th>
              <Th key={"phoneNumber"}>Phone Number</Th>
              <Th key={"created"}>Created</Th>
              <Th key={"views"}>VIEWS</Th>
              <Th key={"tags"}>Tags</Th>
              {/* <Th key={"deleted"}>Deleted</Th> */}
              <Th key={"button"}></Th>
            </Tr>
          </Thead>
          {isArray(accounts) && accounts.length ? (
            <Tbody key={"Table-body"}>
              {accounts.filter(search).map((value, index: number) => {
                return (
                  <Tr key={value._id}>
                    <Td key={"Sr. No"}>{index + 1}</Td>
                    <Td key={"First Name"}>{value.firstName}</Td>
                    <Td key={"Last Name"}>{value.lastName}</Td>
                    <Td key={"Email"}>{get(value, "user.email")}</Td>
                    <Td key={"Email"}>
                      {get(value, "user.phoneNumber")
                        ? get(value, "user.phoneNumber")
                        : NaN}
                    </Td>
                    <Td key={"created"}>
                      {value.createdAt
                        ? moment(value.createdAt).format(
                            "MMMM Do, YYYY, h:mm:ss a"
                          )
                        : "none"}
                    </Td>
                    <Td key={"Views"}>{value.views ? value.views : 0}</Td>
                    <Td key={"Tags"}>{get(value, "tags", []).length}</Td>
                    {/* <Td key={"Delete_flag"} color={"red"}>
                      {value.isDeleted ? "DELETED" : false}
                    </Td> */}
                    <Td key={"LogIn"}>
                      <IconButton
                        aria-label="View Blog"
                        icon={<IoMdLogIn />}
                        marginRight={2}
                        disabled={value.isDeleted}
                        onClick={() => {
                          getAuthToken(get(value, "user._id"));
                        }}
                      />

                      <IconButton
                        aria-label="Delete Account"
                        icon={<IoMdTrash />}
                        marginLeft={2}
                        disabled={value.isDeleted}
                        onClick={() => {
                          openDeleteDataModal(value);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          ) : (
            <Tbody>
              <Tr>
                <Td>Nothing to show here</Td>
              </Tr>
            </Tbody>
          )}
          <Tbody></Tbody>
        </Table>
      </div>
      {isDelete && deleteData && (
        <AccountDeleteConfirmation
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          account={deleteData}
        />
      )}
    </>
  );
};

export default AccountTable;
